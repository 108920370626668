import React, {useContext, useEffect, useState} from "react";
import { langContext } from "../App";
import { langLogin } from "./loginTranslate";
import {Link} from "react-router-dom"
import Lang from "../components/Lang/Lang"
import Logo from "../components/Logo";
import Footer from "../components/Footer"

import "./login.css"
import { axiosInstance } from "../const/axiosInstance";
import { NotificationContainer } from "react-notifications";


const LoginFirst = ((props) => {
    const lang = useContext(langContext);
    let showBySelected = langLogin[lang]

    let [showPass, setShowPass] = useState(true)


    let [pin, setPin] = useState("")
    let [error, setError] = useState("")

    async function join() {
        let result = await axiosInstance.post("/auth/checkpin", {pin})
        if(result.data.data?.link){
            window.location.replace(result.data.data.link)
        } else {
            setError(showBySelected.tr_pinNotExist)
        }
    }

    function handleChange(e) {
        setError("")
        setPin(e.target.value)
    }


    useEffect(() => {
        let isExist = window.localStorage.getItem("username");
        let session = window.localStorage.getItem("session");
        if (isExist && session) {
            let result = (async () =>
                await axiosInstance.post("/devices/session", {
                    session,
                }))();
            result.then((d) => {
                if (d.data.status) {
                    if ((isExist = "Admin")) {
                        window.location = "/dashboard";
                    } else {
                        window.location = "/home";
                    }
                }
            });
        }
    });


    return (
        <div className="login-page" style={{"height": window.innerHeight}}>
            <Lang change={props.change} value={props.value}/>
            <Logo />
            <div className="background-div">
                <div className="info-div">           
                        <h3 className="fontstyle head3" >WORK MEETINGS</h3>             
                        <h3 className="fontstyle head3" >{showBySelected.tr_confSystem}</h3>
                        <h1 className="fontstyle head1" >{showBySelected.tr_logIn}</h1>
                                <>
                                    <p className="fontstyle label-p" >{showBySelected.tr_fillThePin}</p>
                                    <div className="info-center">
                                        <div className="info-input-div">
                                            <img src="/Iconpin.png" className="icons-input" />
                                            <input type={showPass ? "password" : "text"} name="pin_code" placeholder={showBySelected.tr_fillAcceptedPin} value={pin}
                                                onKeyDown={(event) => event.key === 'Enter' ? join() : ''}
                                                    onChange={(e) => handleChange(e)}
                                                    className="info-input" />
                                            {showPass ? <img src="/Iconeye.png" className="eye" onClick={() => {setShowPass(!showPass)}}/> : <img src="/Iconcloseeye.png" className="eye" onClick={() => {setShowPass(!showPass)}}/>}
                                        </div>
                                    </div>
                                    {error ? 
                            <span className="fontstyle" style={{"color":"tomato", "font-size":"14px"}}>{error}</span>
                            :<div style={{"height":"14px"}}></div>}
                                    <div className="info-center">
                                            <button onClick={join}
                                                    className="fontstyle">
                                                {showBySelected.tr_logIn}
                                            </button>
                                    </div>  
                                    <div className="info-center">
                                            <div className="help-and">
                                                <a href="/" target="_blank" rel="noreferrer"
                                                   className="fontstyle">
                                                    {showBySelected.tr_help}
                                                </a>                                      
                                            
                                                <Link to="/register-pin"
                                                   className="fontstyle">
                                                    {showBySelected.tr_registr}
                                                </Link>
                                                
                                            </div>
                                    </div>
                                </>
                                <NotificationContainer/>
                </div>
            </div>
        < Footer />
        </div>
    );
})
export default LoginFirst;
